import { NearShop } from 'api/chaletApi';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Branch } from 'types/Branch';
dayjs.extend(utc);
dayjs.extend(timezone);

//is used when the data provide from NearShop
//deprecated
export const isStoreOpen = (shop: NearShop) => {
  const now = dayjs();
  const actualTime = dayjs('1970-01-01')
    .set('hour', now.hour())
    .set('minute', now.minute())
    .set('second', now.second())
    .utcOffset(0, true);

  if (
    actualTime.isAfter(shop.openDate) &&
    actualTime.isBefore(shop.closeDate)
  ) {
    return true; // the shop is open
  } else {
    return false; // the shop is closed
  }
};
//deprecated
export const getStoreStatusCode = (shop: NearShop) => {
  const now = dayjs();
  const actualTime = dayjs('1970-01-01')
    .set('hour', now.hour())
    .set('minute', now.minute())
    .set('second', now.second())
    .utcOffset(0, true);

  // the shop hasn't open yet
  if (actualTime.isBefore(shop.openDate)) {
    return 0;
  }

  // the shop is already closed
  if (actualTime.isAfter(shop.closeDate)) {
    return 1;
  }

  // shops is already open
  return 2;
};

//is used in BranchPage
export const isBranchOpen = (schedule: any, branch: Branch) => {
  const closes = schedule.closes;
  const opens = schedule.opens;

  if (schedule.closed) {
    return false;
  }

  if (branch.isTemporarilyClosed) {
    return false;
  }

  const now = dayjs().tz(branch.timezone);
  const startTime = dayjs(opens, 'HH:mm:ss');
  const endTime = dayjs(closes, 'H:mm:ss');

  if (now.isAfter(startTime) && now.isBefore(endTime)) {
    return true; // the shop is open
  } else {
    return false; // the shop is closed
  }
};

export const getBranchStatusCode = (schedule: any, timezone: string) => {
  const closes = schedule.closes;
  const opens = schedule.opens;

  const now = dayjs().tz(timezone);
  const startTime = dayjs(opens, 'HH:mm:ss');
  const endTime = dayjs(closes, 'H:mm:ss');

  if (now.isBefore(startTime)) {
    return 0; // the shops hasn't open yet
  }

  if (now.isAfter(endTime)) {
    return 1; // the shop is already closed
  }

  return 2; // the shop is already open
};
