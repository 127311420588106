export const getCurrentDay = () => {
  const date = new Date();
  const day = date.getDay() as keyof typeof originalDateDaysMap;
  return originalDateDaysMap[day];
};

const originalDateDaysMap = {
  0: 1,
  1: 2,
  2: 3,
  3: 4,
  4: 5,
  5: 6,
  6: 7,
};

export const daysOfTheWeek: { [key: number]: string } = {
  1: 'Lunes',
  2: 'Martes',
  3: 'Miércoles',
  4: 'Jueves',
  5: 'Viernes',
  6: 'Sábado',
  7: 'Domingo',
};
