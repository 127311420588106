import { DeliveryDining } from '@mui/icons-material';
import {
  Avatar,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import { BranchWithTodayScheduleUI } from 'types/Branch';
import { formatTime, formatTimeString } from 'utils/formatter';

type BranchCardProps = {
  branch: BranchWithTodayScheduleUI;
  OpenShop: (branchId: number) => void;
};

export const BranchCard: React.FC<BranchCardProps> = ({ branch, OpenShop }) => {
  const mobile_Tablet = useMediaQuery('(max-width:650px)');
  const desktop = useMediaQuery('(min-width:800px)');

  return (
    <Card
      elevation={0}
      key={branch.id}
      sx={{
        margin: { xs: 0, sm: 1, md: 2 },
        width: mobile_Tablet ? '100vw' : desktop ? '350px' : '300px',
        cursor: 'pointer',
        ':hover': {
          boxShadow: '0 0 0 1px #fff, 0 0 0 3px #000',
        },
      }}
      onClick={() => OpenShop(branch.id)}
    >
      <CardMedia
        sx={{
          height: 180,
          borderRadius: '5px',
          margin: 1,
        }}
        image={branch.coverImage}
        title="cover"
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item>
            <Avatar
              variant="square"
              src={branch.profileImage}
              sx={{ width: 80, height: 80 }}
            />
          </Grid>
          <Grid item xs={8} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography variant="h6" fontSize={18}>
                  {branch.name}
                </Typography>

                {branch.isWithinSchedule &&
                  (branch.isTemporarilyClosed ? (
                    <Typography variant="body2">
                      Cerrado temporalmente
                    </Typography>
                  ) : branch.isOpen ? (
                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                      <DeliveryDining sx={{ display: 'flex' }} />
                      <Typography variant="body2">
                        {branch.estimatedOrderTime} -{' '}
                        {branch.estimatedOrderTime + 15} min
                      </Typography>
                    </Stack>
                  ) : (
                    <Typography variant="body2">Cerrado hoy</Typography>
                  ))}

                {branch.isBranchAlreadyClosed &&
                  (branch.isOpen ? (
                    <Typography variant="body2">Cerrado por hoy</Typography>
                  ) : (
                    <Typography variant="body2">Cerrado hoy</Typography>
                  ))}

                {branch.isBranchNotYetOpen &&
                  (branch.isOpen ? (
                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                      <DeliveryDining sx={{ display: 'flex' }} />
                      <Typography variant="body2">
                        Abre a las {formatTime(branch.todaySchedule.startTime)}
                      </Typography>
                    </Stack>
                  ) : (
                    <Typography variant="body2">Cerrado hoy</Typography>
                  ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Divider></Divider>
    </Card>
  );
};
