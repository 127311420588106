import React, { useMemo, useState } from 'react';
import chaletApi from '../../../api/chaletApi';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Container,
  Paper,
  Grid,
  Typography,
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { BranchMenuTabPanel } from '../../components/partials/BranchMenuTabPanel';
import {
  DeliveryDining,
  Schedule,
  ShoppingCartOutlined,
} from '@mui/icons-material';
import { BackButton } from '../../components/common/BackButton/BackButton';
import { getCurrentDay } from 'utils/date';
import { Cart } from 'ui/components/partials/Cart';
import { ButtonCart, CustomAvatar, imgStyle } from './style';
import { getBranchStatusCode, isBranchOpen } from 'utils/store';
import { formatTime } from 'utils/formatter';
import { useAppSelector } from 'redux/hook';

export const Branch = () => {
  const { branchId } = useParams();
  const id = parseInt(branchId || '0', 10);

  const { isLoggedIn } = useAppSelector((state) => state.userAuthSlice);
  const { uniqueId } = useAppSelector((state) => state.device);
  const [openCart, setOpenCart] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };
  const back = () => {
    navigate(-1);
  };

  const handleStateViewDrawer = () => {
    setOpenCart((state) => !state);
  };

  const { data: resultCartUser } = chaletApi.useGetCartByUserQuery(id, {
    skip: !isLoggedIn || !id,
  });
  const { data: resultCartDevice } = chaletApi.useGetCartByDeviceQuery(
    { id, uniqueId },
    {
      skip: isLoggedIn || !uniqueId,
    },
  );

  const currentSchedule = useMemo(() => {
    const scheduleData = isLoggedIn
      ? resultCartUser?.schedule
      : resultCartDevice?.schedule;
    if (scheduleData) {
      const schedule = scheduleData.map(
        ({ day, startTime, endTime, closed }: any) => ({
          day,
          opens: startTime,
          closes: endTime,
          closed: closed,
        }),
      );
      const currentDay = getCurrentDay();
      const getschedule = schedule.find((x: any) => x.day === currentDay);
      if (getschedule?.closed) {
        setOpen(true);
      }
      return getschedule;
    }
  }, [resultCartUser, resultCartDevice]);

  if (branchId === undefined) {
    return <Typography>Tienda no encontrada</Typography>;
  }

  const branch = isLoggedIn ? resultCartUser : resultCartDevice;

  if (branch) {
    return (
      <>
        <BackButton
          toggleOpenCart={handleStateViewDrawer}
          schedule={currentSchedule}
          branch={branch}
        />
        <Container maxWidth="md">
          <Paper>
            <Box>
              <Box display="flex" justifyContent="center">
                <img style={imgStyle} src={branch.coverImage} alt="Cover" />
              </Box>
            </Box>
            <Box>
              <Grid container>
                <Grid item xs={8} sm={8} md={8}>
                  <Box sx={{ ml: 2 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      {branch.name}
                    </Typography>
                    <Stack direction="row" alignItems={'center'} spacing={1}>
                      <Schedule />
                      {currentSchedule?.closed ? (
                        <Typography>CERRADO</Typography>
                      ) : (
                        <>
                          {getBranchStatusCode(
                            currentSchedule,
                            branch.timezone,
                          ) === 1 ? (
                            <Typography>CERRADO</Typography>
                          ) : getBranchStatusCode(
                              currentSchedule,
                              branch.timezone,
                            ) === 0 ? (
                            <Typography variant="body2">
                              Abre a las {formatTime(currentSchedule?.opens)}
                            </Typography>
                          ) : branch.isTemporarilyClosed ? (
                            <Typography variant="body2">
                              Cerrado temporalmente
                            </Typography>
                          ) : (
                            <Typography variant="body2">
                              {formatTime(currentSchedule?.opens)} -{' '}
                              {formatTime(currentSchedule?.closes)}
                            </Typography>
                          )}
                        </>
                      )}
                    </Stack>
                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                      <DeliveryDining />
                      <Typography variant="body2">
                        {branch.estimatedOrderTime} -{' '}
                        {branch.estimatedOrderTime + 15} min
                      </Typography>
                    </Stack>
                  </Box>
                </Grid>
                <Grid item xs={4} sm={4} md={4} alignItems={'center'}>
                  <Box display={'flex'} justifyContent={'center'}>
                    <CustomAvatar src={branch.profileImage} />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ pr: 2, pl: 2 }}>
                    <Typography style={{ fontSize: 16 }} maxWidth={'450px'}>
                      {branch.description}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <BranchMenuTabPanel
              branchMenus={branch.branchMenus}
              branchId={branch.id}
            />
            <ButtonCart
              sx={{ display: { xs: 'flex', sm: 'none' } }}
              variant="contained"
              onClick={() => handleStateViewDrawer()}
              startIcon={<ShoppingCartOutlined />}
              disabled={!isBranchOpen(currentSchedule, branch)}
            >
              Ver carrito
            </ButtonCart>
          </Paper>
          <Cart
            open={openCart}
            handleStateViewDrawer={handleStateViewDrawer}
            shopId={branch.shopId}
            cart={branch}
          />
        </Container>

        {/*Message if the branch is closed today*/}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Este comercio se encuentra cerrado'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              ¿Quieres ver el menú?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={back}>Regresar</Button>
            <Button onClick={handleClose} autoFocus>
              Sí
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  return null;
};
