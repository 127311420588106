import {
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import chaletApi from 'api/chaletApi';
import React from 'react';
import { CloseRounded } from '@mui/icons-material';
import toast from 'react-hot-toast';
import { DeliveryMap } from '../delivery_map';
import { OrderCard } from '../OrderCard';
import { AddOrderDetailStep } from './Steppers';

const CustomBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  maxHeight: '90vh',
  backgroundColor: 'white',
  borderRadius: '0.5em',
  boxShadow: '0px 0px 50px 5px rgba(0, 0, 0, 0.5)',
  padding: 15,
  boxSizing: 'border-box',
  overflowY: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '90%',
    height: 'auto',
    maxHeight: '80vh',
    fontSize: '1rem',
  },
}));

type OrderModalProps = {
  open: boolean;
  handleClose: () => void;
  orderId: number;
};

export const OrderModal: React.FC<OrderModalProps> = ({
  open,
  handleClose,
  orderId,
}) => {
  const { data, isError } = chaletApi.useGetOneOrderQuery(orderId);
  const [cancelOrder] = chaletApi.useCancelOrderMutation();
  const [showDelete, setShowDelete] = React.useState<boolean>(false);
  const [cancelReason, setCancelReason] = React.useState<string>('');
  const [addItems, setAddItems] = React.useState(false);

  if (isError) {
    return (
      <p>
        Error: No se pudo cargar los datos. Por favor, inténtalo de nuevo más
        tarde.
      </p>
    );
  }

  if (data) {
    const ValidateCancelOrder = () => {
      if (data?.currentOrderProcessStep.correlative === 1) {
        setShowDelete(true);
      } else {
        toast.error('La orden ya está en proceso, no se puede cancelar');
      }
    };

    const CancelOrder = () => {
      const CancelOrderBody = {
        orderId: data.id,
        reason: cancelReason,
      };
      cancelOrder(CancelOrderBody);
    };

    const handleCancelReason = (event: React.ChangeEvent<HTMLInputElement>) => {
      setCancelReason(event.target.value);
    };

    const handleAddItems = () => {
      setAddItems((prevAddItems) => !prevAddItems);
    };

    const shouldHideButton =
      data.isCanceled ||
      data.isCompleted ||
      ['ready_for_pickup', 'out_for_delivery'].includes(
        data.currentOrderProcessStep.status,
      );

    const preparedByDate = new Date(data.preparedByDate);
    preparedByDate.setMinutes(preparedByDate.getMinutes() + 15);

    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomBox>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" style={{ fontStyle: 'bold' }}>
              Detalle de la orden
            </Typography>
            <IconButton color="primary" onClick={handleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>

          {data.currentOrderProcessStep.status === 'confirmed' && (
            <Typography variant="body1" sx={{
              color: 'primary.main',
            }}>
              Entrega estimada:{' '}
              {preparedByDate.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
              })}
            </Typography>
          )}

          <Box maxHeight={600} overflow="auto">
            <DeliveryMap order={data} />
            <OrderCard order={data} />
          </Box>

          <Box
            display={showDelete ? 'none' : 'flex'}
            justifyContent={'space-between'}
            mt={4}
          >
            <Button
              variant="contained"
              color="error"
              onClick={ValidateCancelOrder}
              sx={{
                display: data.isCanceled || data.isCompleted ? 'none' : 'block',
              }}
              disabled={showDelete || addItems}
            >
              Cancelar Orden
            </Button>
            <Button
              variant="outlined"
              onClick={handleAddItems}
              sx={{
                display: shouldHideButton ? 'none' : 'block',
              }}
            >
              {addItems ? 'Cancelar' : 'Agregar más productos'}
            </Button>
          </Box>

          {addItems && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                mt: 6,
              }}
            >
              <AddOrderDetailStep branchId={data.branch.id} orderId={data.id} />
            </Box>
          )}

          {showDelete && (
            <Box
              component={'form'}
              display={'flex'}
              justifyContent={'center'}
              flexDirection={'column'}
              mt={2}
              onSubmit={CancelOrder}
            >
              <TextField
                fullWidth
                id="outlined-multiline-static"
                label="¿Por qué quieres cancelar?"
                name="cancelReason"
                multiline
                rows={3}
                required
                onChange={handleCancelReason}
              />

              <Button
                type="submit"
                sx={{ mt: 1 }}
                variant="contained"
                color="error"
              >
                Confirmar
              </Button>
            </Box>
          )}
        </CustomBox>
      </Modal>
    );
  }
};
