import { ArrowBack, ShoppingCartOutlined } from '@mui/icons-material';
import { AppBar, Box, Button, Container, Toolbar } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Branch } from 'types/Branch';
import routesConstants from 'utils/routesConstants';
import { isBranchOpen } from 'utils/store';

type backButtonProps = {
  toggleOpenCart?: () => void;
  schedule?: any;
  branchId?: string;
  branch: Branch;
};

export const BackButton: React.FC<backButtonProps> = ({
  toggleOpenCart,
  schedule,
  branchId,
  branch,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleBack = () => {
    if (location.pathname.includes('/product')) {
      navigate(routesConstants.branch(branchId));
    } else {
      navigate(routesConstants.home);
    }
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          top: {
            xs: '60px',
            sm: '65px',
            md: '70px',
          },
          paddingY: '5px',
          zIndex: (theme) => theme.zIndex.appBar + 1,
          backgroundColor: '#FFFFFF',
          transition: 'all 0.3s ease-in-out',
        }}
      >
        <Container maxWidth="xl">
          <Toolbar>
            <Box sx={{ flexGrow: 1 }}>
              <Button
                color="primary"
                aria-label="back"
                onClick={handleBack}
                startIcon={<ArrowBack />}
              >
                Atrás
              </Button>
            </Box>

            {toggleOpenCart && (
              <Button
                sx={{
                  display: { xs: 'flex', sm: 'flex' },
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  padding: '10px 20px',
                  minWidth: '150px',
                }}
                variant="contained"
                onClick={toggleOpenCart}
                startIcon={<ShoppingCartOutlined />}
                disabled={!isBranchOpen(schedule, branch)}
              >
                Ver carrito
              </Button>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <Box
        sx={{
          mt: '65px',
        }}
      />
    </>
  );
};
